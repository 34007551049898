const translation = {
  分析: '对话',
  复制成功: '复制成功',
  所属经理: '所属经理',
  投资策略: '投资策略',
  目标: '目标',
  状态: '状态',
  建立日期: '建立日期',
  最近分析: '最近分析',
  风险概况: '风险概况',
  选择客户: '选择客户',
  刚刚: '刚刚',
  save: '确定',
  cancel: '取消',
  viewAll: '查看全部',
  moreLikeThis: '更多类似产品',
  chat: {
    思考中: '思考中',
    思考秒: '思考 {{number}} 秒',
    推荐问: '\n推荐问:',
    消息异常: '消息异常',
    conversations: '会话列表',
    newConversation: '新建会话',
    deleteTip: '删除提示',
    confirmDeletion: '确认删除？',
    exportToPdf: '导出为pdf',
    sendToEmail: '发送至邮箱',
    exportTip: '导出提示',
    contentTooLong: "内容过长，请手动复制内容。"
  },
  index: {
    title: '资产变化',
    亏损客户数: '亏损账户',
    盈利客户数: '盈利账户',
    全部: '全部',
    亏损: '亏损',
    盈利: '盈利',
  },
  menus: {
    首页: '首页',
    文档: '文档',
    客户: '客户',
    指导: '指导',
    定价: '定价',
  },
  login: {
    选择角色: '请选择一个角色，你是',
    genieTitle: '您的财富管理助手',
    登录描述: '欢迎使用我们的理财助手-与我们一起开启您的财富管理之旅，体验智能理财的魅力！',
    请输入用户名: '请输入用户名',
    请输入邮箱: '请输入邮箱',
    获取验证码: '获取验证码',
    请输入邮箱验证码: '请输入邮箱验证码',
    请确认密码: '请确认密码',
    FirstName: '性',
    LastName: '名',
    请输入手机号: '请输入手机号',
    输入邮箱: '输入您的邮箱',
    输入密码: '输入您的密码',
    请输入密码: '请输入密码',
    账号密码不正确: '邮箱或密码不正确',
    注册账号: '注册账号',
    登录: '登录',
    注册: '注册',
    不能为空: 'Required',
    密码规则: '密码必须包含至少8个字符，包含数字、字母和特殊字符',
    两次输入的密码不一致: '两次输入的密码不一致',
    邮箱格式不正确: '邮箱格式不正确',
    注册失败: '注册失败',
    注册成功: "注册成功",
    发送验证码失败: '发送验证码时出现错误，请稍后再试',
    注册失败1: "用户名至少要 3 个字符长",
    注册失败2: "邮箱已存在",
    注册失败3: "用户名已存在",
    注册失败4: "电话号码已存在",
    注册失败5: "电话号码必须是数字",
    注册失败6: "验证码错误",
    logout: '退出',
    个人信息: '编辑个人信息',
    公司: '公司',
    邮箱: '邮箱',
  },
  userDetailTable: {
    title: '投资组合交易历史',
    downTip: '下载交易历史',
    投资类别: '投资类别',
    特定投资或证券的名称: '投资名称',
    公开交易证券的股票代码或符号: '代码',
    客户持有的股份或单位数量: '持有数量',
    最初购买时的价格: '购买价格',
    当前市场价格: '当前市场价格',
    总价值: '总价值',
    原始成本: '原始成本',
    市场价值与成本基础之间的差额: '未实现利润',
    年收益率: '年收益率',
    投资收入: '投资收入',
    总资产分配给此资产的比例: '资产比例',
    风险水平: '风险水平',
    更新投资组合数据的日期: '购买日期',
  },
  upload: {
    文件类型不支持: '文件类型不支持',
    文件太大: '文件过大，不能超过 {{size}}MB',
    上传成功: '上传成功',
    上传限制个数: '批量上传限制 {{number}} 个文件',
    拖拽文件至此或者: '拖拽文件到此，或',
    下载模板: '下载模板',
    下载用户数据: '从Genie下载客户账户数据',
    下载理财数据: '从Genie下载投资组合交易历史数据',
    上传用户或理财信息: '上传账户或财富数据',
    上传理财信息: '上传理财信息',
    选择文件: '选择文件',
    支持文件: '支持 {{exp}}，每个文件不能超过 {{size}}MB',
    importAccountData: '导入客户账户数据到Genie',
    importPortfolioTransaction: '导入投资组合交易历史数据到Genie',
    importTemplate: '导入客户账户数据到Genie',
    importPortfolioTemplate: '导入投资组合交易历史数据到Genie',
  },
  api: {
    success: '成功',
    actionSuccess: '操作成功',
    saved: '已保存',
    create: '已创建',
    remove: '已移除',
    deleFail: '删除失败',
  },
  price: {
    free: '免费',
    title: 'Genie',
    subtitle: 'AI驱动的财富管理助手',
    perMonth: '每月',
    includes: '包含',
    plusPlan: 'Plus套餐',
    mostPopular: '最受欢迎',
    proPlan: 'Pro套餐',
    paymentMethods: 'Stripe和PayPal支付',
    "description": {
      "free": "适合个人投资者入门使用，提供基础的投资组合分析和管理功能，帮助您开启智能理财之旅。",
      "plus": "面向进阶投资者，提供更深入的投资分析和多样化的投资策略建议，助您实现更优的投资收益。",
      "pro": "为专业投资者打造，提供全方位的投资管理解决方案，包含高级分析工具和个性化投资顾问服务。"
    },
    features: {
      analysis: '投资组合分析和报告',
      optimization: '个性化投资\n优化和\n建议',
      trends: '市场趋势洞察',
      decisions: '透明的决策过程',
      strategy: '目标导向的投资策略',
      monitoring: '投资组合绩效监控',
      simulation: '情景模拟',
      tax: '税收效率优化',
      portfolios2: '最多2个投资组合',
      portfolios10: '最多10个投资组合',
      portfolios50: '最多50个投资组合',
      trial: '新用户1个月免费试用',
      freePlan: '+ 所有免费版功能'
    },
    extraCredits: {
      title: '额外积分',
      description: '适用于所有等级',
      price: '￥140',
      unit: '每10,000积分'
    },
    extraStorage: {
      title: '额外知识存储',
      description: '适用于所有等级',
      price: '￥700',
      unit: '每GB'
    }
  },
  overview: {
    title: '概览',
    thisWeek: '本周',
    thisMonth: '本月',
    thisYear: '本年',
    totalAccounts: '总账户数',
    totalAssets: '总账户资产',
    accounts: '我的账户概览',
    account: '账户',
    expenseCategory: '投资组合配置',
    risk: '风险',
    balance: '余额',
    unrealizedProfits: '未实现收益',
    objective: '目标',
    investmentStrategies: '投资策略',
    actions: '操作',
    portfolioTrend: '投资组合趋势'
  },
  settings: {
    title: '设置',
    Basics: '基础设置',
    profileInformation: '个人信息',
    uploadNewPicture: '上传新照片',
    portfolioObjective: "投资组合目标",
    risk: '风险',
    investmentStrategies: '投资策略',
    customizedInvestmentStrategies: '定制投资策略'
  }
}

export default translation
