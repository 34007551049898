import(/* webpackMode: "eager" */ "/mnt/d/develop/wealth-manager/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/mnt/d/develop/wealth-manager/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/mnt/d/develop/wealth-manager/src/components/providers.tsx");
;
import(/* webpackMode: "eager" */ "/mnt/d/develop/wealth-manager/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/mnt/d/develop/wealth-manager/src/styles/markdown.scss");
;
import(/* webpackMode: "eager" */ "/mnt/d/develop/wealth-manager/src/styles/sass.sass");
