const translation = {
  分析: 'Chat',
  复制成功: 'Copy successful',
  所属经理: 'Manager',
  投资策略: 'Investment Strategy',
  目标: 'Objective',
  状态: 'Status',
  建立日期: 'Creation Date',
  最近分析: 'Latest Analysis Date',
  风险概况: 'Risk Profile',
  选择客户: 'Select Customer',
  刚刚: 'Just now',
  save: 'Yes',
  cancel: 'Cancel',
  viewAll: 'View all',
  moreLikeThis: 'More like this',
  chat: {
    思考中: 'Thinking',
    思考秒: 'Thinking for {{number}} seconds',
    推荐问: '\nRecommended Questions:',
    消息异常: 'Message Exception',
    conversations: 'Conversations',
    newConversation: 'New Conversation',
    deleteTip: 'Delete tip',
    confirmDeletion: 'Confirm deletion?',
    exportToPdf: 'Export to pdf',
    sendToEmail: 'Send to email',
    exportTip: 'Export Tip',
    contentTooLong: "The content is too long. Please copy the content manually."
  },
  index: {
    title: 'Client P&L Ratio',
    亏损客户数: 'Underperforming Accounts',
    盈利客户数: 'Profitable Accounts',
    全部: 'All',
    亏损: 'Loss',
    盈利: 'Profit',
  },
  menus: {
    首页: 'Home',
    文档: 'Data',
    客户: 'Accounts',
    指导: 'User Guide',
    定价: 'Subscriptions',
  },
  login: {
    选择角色: 'Please select your role, are you a',
    genieTitle: 'YOUR WEALTH MANAGER COPILOT',
    登录描述: 'Welcome to our financial assistant - experience intelligent finance with us! Quack!',
    请输入用户名: 'Please enter username',
    请输入邮箱: 'Please enter email',
    获取验证码: 'Get code',
    请输入邮箱验证码: 'Enter email verification code',
    请确认密码: 'Please confirm the password',
    FirstName: 'First Name',
    LastName: 'Last Name',
    请输入手机号: 'Please enter phone number',
    输入邮箱: 'Enter your email',
    输入密码: 'Enter your password',
    请输入密码: 'Please enter password',
    账号密码不正确: 'Email or password is not correct',
    注册账号: 'Register',
    登录: 'Login',
    注册: 'Register',
    不能为空: 'Required',
    密码规则: "Password must be at least 8 characters long and include numbers, letters, and special characters",
    两次输入的密码不一致: "The two passwords entered do not match",
    邮箱格式不正确: "Invalid email format",
    注册失败: "Registration failed",
    注册成功: "Registration succeeded.",
    发送验证码失败: "An error occurred while sending the verification code, please try again later",
    注册失败1: "Username must be at least 3 characters long",
    注册失败2: "Email already exists",
    注册失败3: "Username already exists",
    注册失败4: "Phone number already exists",
    注册失败5: "Phone number must be numeric",
    注册失败6: "Code is error",
    logout: 'Logout',
    个人信息: 'Edit profile',
    公司: 'Company',
    邮箱: 'Email',
  },
  userDetailTable: {
    title: 'Portfolio Transaction History',
    downTip: 'Download Transaction History',
    投资类别: 'Asset Class',
    特定投资或证券的名称: 'Name',
    公开交易证券的股票代码或符号: 'Symbol',
    客户持有的股份或单位数量: 'Quantity Held',
    最初购买时的价格: 'Purchase Cost',
    当前市场价格: 'Current Price',
    总价值: 'Market Value',
    原始成本: 'Cost Basis',
    市场价值与成本基础之间的差额: 'Unrealized Gain/Loss',
    年收益率: 'Annual Yield ',
    投资收入: 'Income',
    总资产分配给此资产的比例: 'Allocation ',
    风险水平: 'Risk Level',
    更新投资组合数据的日期: 'Purchase Date',
  },
  upload: {
    文件类型不支持: 'File type not supported',
    文件太大: 'File is too large, cannot exceed {{size}}MB',
    上传成功: 'Upload successful',
    上传限制个数: 'Batch upload limit {{number}} files',
    拖拽文件至此或者: 'Drag files here, or',
    下载模板: 'Download template',
    下载用户数据: 'Download customer account data from Genie',
    下载理财数据: 'Download portfolio transaction history data from Genie',
    上传用户或理财信息: 'Upload account or wealth data',
    上传理财信息: 'Upload financial information',
    选择文件: 'Select files',
    支持文件: 'Supported {{exp}}, each file cannot exceed {{size}}MB',
    importAccountData: 'Import account data into Genie',
    importPortfolioTransaction: 'Import portfolio transaction for a customer account into Genie',
    importTemplate: 'Import customer account data into Genie',
    importPortfolioTemplate: 'Import portfolio transaction history data into Genie',
  },
  api: {
    success: 'Success',
    actionSuccess: 'Action Successful',
    saved: 'Saved',
    create: 'Created',
    remove: 'Removed',
    deleFail: 'Delete failed',
  },
  price: {
    free: 'Free',
    title: 'Genie',
    subtitle: 'AI-powered Wealth Manager Copilot',
    perMonth: 'per month',
    includes: 'Includes',
    plusPlan: 'Plus Plan',
    mostPopular: 'most popular',
    proPlan: 'Pro Plan',
    paymentMethods: 'Stripe and PayPal',
    "description": {
      "free": "Perfect for individual investors getting started, offering basic portfolio analysis and management features to help you begin your smart investment journey.",
      "plus": "Designed for advanced investors, providing deeper investment analysis and diverse strategy recommendations to help you achieve better investment returns.",
      "pro": "Built for professional investors, delivering comprehensive investment management solutions, including advanced analytics tools and personalized advisory services."
    },
    features: {
      analysis: 'Portfolio analysis and report',
      optimization: 'Personalized investment\noptimization and\nrecommendations',
      trends: 'Market trends insights',
      decisions: 'Transparent decision-making',
      strategy: 'Goal-oriented investment strategy',
      monitoring: 'Portfolio performance monitoring',
      simulation: 'Scenario Simulation',
      tax: 'Tax efficiency optimization',
      portfolios2: 'Up to 2 portfolios',
      portfolios10: 'Up to 10 portfolios',
      portfolios50: 'Up to 50 portfolios',
      trial: 'New user 1 month free trail',
      freePlan: '+ All Free Plan Features'
    },
    extraCredits: {
      title: 'Extra Credits',
      description: 'Available on all tiers.',
      price: '$20',
      unit: 'per 10,000 credits'
    },
    extraStorage: {
      title: 'Extra Knowledge Storage',
      description: 'Available on all tiers.',
      price: '$100',
      unit: 'per GB'
    }
  },
  overview: {
    title: 'Overview',
    thisWeek: 'This week',
    thisMonth: 'This month',
    thisYear: 'This year',
    totalAccounts: 'Total Accounts',
    totalAssets: 'Assets of Total Accounts',
    accounts: 'My Account Dashboard',
    account: 'Account',
    expenseCategory: 'Portfolio Allocation',
    risk: 'Risk',
    balance: 'Balance',
    unrealizedProfits: 'Unrealized Profits',
    objective: 'Objective',
    investmentStrategies: 'Investment Strategies',
    actions: 'Actions',
    portfolioTrend: 'Portfolio Trend'
  },
  settings: {
    title: 'Settings',
    Basics: 'Basics',
    profileInformation: 'Profile Information',
    uploadNewPicture: 'Upload new picture',
    portfolioObjective: "Portfolio's Objective",
    risk: 'Risk',
    investmentStrategies: 'Investment Strategies',
    customizedInvestmentStrategies: 'Customized Investment Strategies'
  }
}

export default translation