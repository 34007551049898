const translation = {
  分析: '分析',
  复制成功: '複製成功',
  所属经理: '所屬經理',
  投资策略: '投資策略',
  目标: '目標',
  状态: '狀態',
  建立日期: '建立日期',
  最近分析: '最近分析',
  风险概况: '風險概況',
  选择客户: '選擇客戶',
  刚刚: '剛剛',
  save: '確定',
  cancel: '取消',
  viewAll: '查看全部',
  moreLikeThis: '更多類似產品',
  chat: {
    思考中: '思考中',
    思考秒: '思考 {{number}} 秒',
    推荐问: '\n推薦問:',
    消息异常: '消息異常',
    conversations: '會話列表',
    newConversation: '新建會話',
    deleteTip: '刪除提示',
    confirmDeletion: '確認刪除？',
    exportToPdf: '導出為pdf',
    sendToEmail: '發送至郵箱',
    exportTip: '導出提示',
    contentTooLong: "內容過長，請手動複製內容。 "
  },
  index: {
    title: '資產變化',
    亏损客户数: '虧損賬戶',
    盈利客户数: '盈利賬戶',
    全部: '全部',
    亏损: '虧損',
    盈利: '盈利',
  },
  menus: {
    首页: '首頁',
    文档: '文檔',
    客户: '客戶',
    指导: '指導',
    定价: '定價',
  },
  login: {
    选择角色: '請選擇一個角色，你是',
    genieTitle: '您的財富管理助手',
    登录描述: '歡迎使用我們的理財助手-與我們一起開啟您的財富管理之旅，體驗智能理財的魅力！',
    请输入用户名: '請輸入用戶名',
    请输入邮箱: '請輸入郵箱',
    获取验证码: '獲取驗證碼',
    请输入邮箱验证码: '請輸入郵箱驗證碼',
    请确认密码: '請確認密碼',
    FirstName: '性',
    LastName: '名',
    请输入手机号: '請輸入手機號',
    输入邮箱: '輸入您的郵箱',
    输入密码: '輸入您的密碼',
    请输入密码: '請輸入密碼',
    账号密码不正确: '郵箱或密碼不正確',
    注册账号: '註冊帳號',
    登录: '登錄',
    注册: '註冊',
    不能为空: 'Required',
    密码规则: '密碼必須包含至少8個字符，包含數字、字母和特殊字符',
    两次输入的密码不一致: '兩次輸入的密碼不一致',
    邮箱格式不正确: '郵箱格式不正確',
    注册失败: '註冊失敗',
    注册成功: "註冊成功",
    发送验证码失败: '發送驗證碼時出現錯誤，請稍後再試',
    注册失败1: "用戶名至少要 3 個字符長",
    注册失败2: "郵箱已存在",
    注册失败3: "用戶名已存在",
    注册失败4: "電話號碼已存在",
    注册失败5: "電話號碼必須是數字",
    注册失败6: "驗證碼錯誤",
    logout: '退出',
    个人信息: '編輯個人信息',
    公司: '公司',
    邮箱: '郵箱',
  },
  userDetailTable: {
    title: '投資組合交易歷史',
    downTip: '下載交易歷史',
    投资类别: '投資類別',
    特定投资或证券的名称: '投資名稱',
    公开交易证券的股票代码或符号: '代碼',
    客户持有的股份或单位数量: '持有數量',
    最初购买时的价格: '購買價格',
    当前市场价格: '當前市場價格',
    总价值: '總價值',
    原始成本: '原始成本',
    市场价值与成本基础之间的差额: '未實現利潤',
    年收益率: '年收益率',
    投资收入: '投資收入',
    总资产分配给此资产的比例: '資產比例',
    风险水平: '風險水平',
    更新投资组合数据的日期: '購買日期',
  },
  upload: {
    文件类型不支持: '文件類型不支持',
    文件太大: '文件過大，不能超過 {{size}}MB',
    上传成功: '上傳成功',
    上传限制个数: '批量上傳限制 {{number}} 個文件',
    拖拽文件至此或者: '拖拽文件到此，或',
    下载模板: '下載模板',
    下载用户数据: '從Genie下載客戶帳戶數據',
    下载理财数据: '從Genie下載投資組合交易歷史數據',
    上传用户或理财信息: '上傳帳戶或財富數據',
    上传理财信息: '上傳理財信息',
    选择文件: '選擇文件',
    支持文件: '支持 {{exp}}，每個文件不能超過 {{size}}MB',
    importAccountData: '導入客戶帳戶數據到Genie',
    importPortfolioTransaction: '導入投資組合交易歷史數據到Genie',
    importTemplate: '導入客戶帳戶數據到Genie',
    importPortfolioTemplate: '導入投資組合交易歷史數據到Genie',
  },
  api: {
    success: '成功',
    actionSuccess: '操作成功',
    saved: '已保存',
    create: '已創建',
    remove: '已移除',
    deleFail: '刪除失敗',
  },
  price: {
    free: '免費',
    title: 'Genie',
    subtitle: 'AI驅動的財富管理助手',
    perMonth: '每月',
    includes: '包含',
    plusPlan: 'Plus套餐',
    mostPopular: '最受欢迎',
    proPlan: 'Pro套餐',
    paymentMethods: 'Stripe和PayPal支付',
    "description": {
      "free": "適合個人投資者入門使用，提供基示的投資組合分析和管理功能，幫助您開啟智能理財之旅。",
      "plus": "面向進階投資者，提供更深入的投資分析和多樣化的投資策略建議，助您實現更優的投資收益。",
      "pro": "為專業投資者打造，提供全方位的投資管理解決方案，包含高級分析工具和個性化投資顧問服務。"
    },
    features: {
      analysis: '投資組合分析和報告',
      optimization: '個性化投資\n優化和\n建議',
      trends: '市場趨勢洞察',
      decisions: '透明的決策過程',
      strategy: '目標導向的投資策略',
      monitoring: '投資組合績效監控',
      simulation: '情景模擬',
      tax: '稅收效率優化',
      portfolios2: '最多2個投資組合',
      portfolios10: '最多10個投資組合',
      portfolios50: '最多50個投資組合',
      trial: '新用戶1個月免費試用',
      freePlan: '+ 所有免費版功能'
    },
    extraCredits: {
      title: '額外積分',
      description: '適用於所有等級',
      price: '￥140',
      unit: '每10,000積分'
    },
    extraStorage: {
      title: '額外知識存儲',
      description: '適用於所有等級',
      price: '￥700',
      unit: '每GB'
    }
  },
  overview: {
    title: '概覽',
    thisWeek: '本周',
    thisMonth: '本月',
    thisYear: '本年',
    totalAccounts: '總帳戶數',
    totalAssets: '總帳戶資產',
    accounts: '我的账户概览',
    account: '帳戶',
    expenseCategory: '投資組合配置',
    risk: '風險',
    balance: '餘額',
    unrealizedProfits: '未實現收益',
    objective: '目標',
    investmentStrategies: '投資策略',
    actions: '操作',
    portfolioTrend: '投資組合趨勢'
  },
  settings: {
    title: '設置',
    Basics: '基礎設置',
    profileInformation: '個人信息',
    uploadNewPicture: '上傳新照片',
    portfolioObjective: "投資組合目標",
    risk: '風險',
    investmentStrategies: '投資策略',
    customizedInvestmentStrategies: '定制投資策略'
  }
}

export default translation
